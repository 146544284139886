export default {
  planningMapView: {
    baseMap: "Bản đồ nền",
    toolsMap: "Công cụ bản đồ",
    notUseBaseMap: "Không dùng bản đồ nền",
    mapToolsPanel: {
      viewObjectInfomation: "Xem thông tin đối tượng",
      toggleFullScreen: "Mở rộng/Thu nhỏ",
      zoomIn: "Phóng to bản đồ",
      zoomOut: "Thu nhỏ bản đồ",
      filterInfomation: "Lọc thông tin",
      mapLegend: "Chú giải bản đồ",
      selectAnObject: "Chọn một đối tượng",
      selectMultiObject: "Chọn nhiều đối tượng",
      measuareArea: "Đo diện tích",
      measuareDistance: "Đo khoảng cách",
      moveViewport: "Di chuyển khung nhìn",
      printTheMap: "In bản đồ",
      myPosition: "Xác định vị trí của bạn",
      exportMap: "Xuất bản đồ",
      compare: "So sánh",
      locationMarker: "Đánh dấu vị trí",
    },
    filterInfomationPopup: {
      headTitle: "Tìm kiếm đối tượng",
      labelSelectSearch: "Chọn đối tượng tìm kiếm",
      searchInLine: "Tìm kiếm trên đường",
      searchInArea: "Tìm kiếm trong vùng",
      searchInCircle: "TÌm kiếm trong đường tròn",
      searchInSquare: "Tìm kiếm trong hình chữ nhật",
      searchWithAttribute: "Theo thuộc tính",
      search: "Tìm kiếm",
      delete: "Xóa",
      close: "Đóng",
    },
  },
  planningMapView_guideToUse: "Hướng dẫn sử dụng",
  planningMapView_guideToUse_category: "Danh mục hướng dẫn",
  planningMapView_map: "Bản đồ",
  planningMapView_infomation: "Thông tin",
  planningMapView_relatedPlanning: "Quy hoạch liên quan",
  planningMapView_result: "Kết quả",
  header_homePage: "Trang chủ",
  header_pleaseConsultTheCommunity: "Xin ý kiến cộng đồng",
  header_lookUpPlanning: "Tra cứu quy hoạch",
  header_news: "Tin tức",
  header_planningAnnouncement: "Công bố quy hoạch",
  header_recordsManager: "Quản lý hồ sơ",
  header_Contact: "Liên hệ",
  header_generalPlanning: "Quy hoạch chung",
  header_syntheticPlanning: "Tổng hợp quy hoạch",
  header_subdivisionPlanning: "Quy hoạch phân khu",
  header_detailedPlanning: "Quy hoạch chi tiết",
  header_specializedPlanning: "Quy hoạch chuyên ngành",
  header_administration: "Quản trị",
  header_logout: "Đăng xuất",
  header_logIn: "Đăng Nhập",
  header_account: "Tài khoản",
  header_title: "GIS Vĩnh Long",
  footer_titleName: "CỤC CẢNH SÁT PCCC VÀ CỨU NẠN CỨU HỘ",
  footer_email: "cuccspccc@gmail.com",
  footer_email_to: "cuccspccc@gmail.com",
  footer_address: "Số 2A phố Đinh Lễ, quận Hoàn Kiếm, Hà Nội, Việt Nam",
  footer_hotline: " 06923 - 4015",
  footer_hotline_tel: " 06923 - 40159",
  footer_fax: "04 - 3825465",
  footer_note: "Sản phẩm được nghiên cứu và phát triển bởi: Công ty cổ phần công nghệ cao CTECH",
  footer_website: "http://htpccc.cgis.asia",
  footer_website_to: "http://htpccc.cgis.asia",
  contact_map_center_lat: 22.663812151150196,
  contact_map_center_lng: 106.25812657434678,
  contact_map_marker_lat: 22.663812151150196,
  contact_map_marker_lng: 106.25812657434678,
  contact_map_url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.162363112593!2d105.85161211429775!3d21.026188493243414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abeb6a984b3f%3A0xb3291634170de015!2zMiBQaOG7kSDEkGluaCBM4buFLCBUcsOgbmcgVGnhu4FuLCBIb8OgbiBLaeG6v20sIEjDoCBO4buZaSwgVmlldG5hbQ!5e0!3m2!1sen!2s!4v1642254569120!5m2!1sen!2s',
};
