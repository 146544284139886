import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {isMobile} from 'react-device-detect';
function HomeSliderView(props) {
    const getHomeSlider = () => {
        if(isMobile){
            return (
                <section id="mobile-background" className="mobile-background"></section>
            )
        }else{
            return (
                <Carousel
                    autoPlay= {true}
                    showThumbs={false}
                    showArrows = {true}
                    infiniteLoop = {true}
                    dynamicHeight={false}
                >
                    <div>
                        <img
                            height={880}
                            src={require("../../../assets/slider/slider-01.png")}
                        />
                    </div>
                    <div>
                        <img
                            height={880}
                            src={require("../../../assets/slider/slider-02.jpg")}
                        />
                    </div>
                    <div>
                        <img
                            height={880}
                            src={require("../../../assets/slider/slider-03.jpg")}
                        />
                    </div>

                </Carousel>
            )
        }
    }
  return (
      getHomeSlider()
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default  (connect(mapStateToProps, mapDispatchToProps)(HomeSliderView));
