const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
      api: "https://htpccc-api.cgis.asia/",
      DomainUserSite:"http://localhost:3000",
      DomainAdminSite:"http://localhost:3000",
      DomainName:""
  },

    production: {
          api: "https://htpccc-api.cgis.asia/",
          DomainUserSite:"https://htpccc.cgis.asia",
          DomainAdminSite:"https://admin.htpccc.cgis.asia",
          DomainName:"htpccc.cgis.asia"
    }

};

module.exports = apiEnvironment[env];