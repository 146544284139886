import React, { useState, useEffect } from "react";
import "./trial.scss";

function Trial() {
  return (
      <div className="trial-box-container">
          <div id="trial-box">
              <div id="trial-box-1">Toàn bộ thông tin trên website này mang tính chất tham khảo, chỉ sử dụng duy nhất cho mục đích nghiên cứu, lập quy hoạch hạ tầng phòng cháy chữa cháy, thời kỳ 2021 - 2030, tầm nhìn 2050. Mọi hành vi khai thác, sử dụng dữ liệu từ hệ thống này vào mục đích khác nêu trên là vi phạm quy định, và phải chịu hoàn toàn trách nhiệm trước pháp luật.</div>
          </div>
      </div>
  );
}
export default (Trial);
